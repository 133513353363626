<template>
  <div>
    <div class="container d-flex justify-content-between">
      <div>
        <h5>Categorias</h5>
      </div>
      <div>
        <b-button @click="modalShow = true">Agregar</b-button>
      </div>
    </div>
    <hr />
    <b-table
      bordered
      hover
      :fields="fields"
      :items="items"
    >
      <template #cell(acciones)="row">
        <div>
          <b-icon
            @click="openModal(row.item.id)"
            v-b-tooltip.hover
            title="actualizar"
            icon="pencil"
          ></b-icon>
          <b-icon
            class="ml-2"
            @click="handleDeleteCategoria(row.item.id)"
            v-b-tooltip.hover
            title="eliminar"
            icon="trash"
          ></b-icon>
        </div>
      </template>
    </b-table>
    <b-modal
      v-model="modalShow"
      hide-footer
      @hidden="categoria = { id: null, nombre: '' }"
    >
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="handleSubmit()">
          <b-form-group label="Nombre" label-for="nombre">
            <b-form-input
              id="nombre"
              v-model="categoria.nombre"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nombre inglés" label-for="nombreIngles">
            <b-form-input
              id="nombreIngles"
              v-model="categoria.nombreIngles"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary"> Guardar </b-button>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    seccion: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      modalShow: false,
      categoria: {
        id: null,
        nombre: "",
        nombreIngles:"",
      },
      fields: [
        {
          key: "nombre",
          label: "Nombre",
        },
        {
          key: "nombreIngles",
          label: "Nombre inglés",
        },
        {
          key: "acciones",
          label: "Acciones",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
      createCategoria: "categorias/createCategoria",
      updateCategoria: "categorias/updateCategoria",
      deleteCategoria: "categorias/deleteCategoria",
    }),
    openModal(id) {
      this.categoria = {
        ...this.categorias.find((categoria) => categoria.id === id),
      };
      this.modalShow = true;
    },
    async handleSubmit() {
      this.loading = true;
      if (this.categoria.id) {
        console.log("actualizar");
        const res = await this.updateCategoria(this.categoria);
        if (res) {
          this.modalShow = false;
          this.$swal({
            title: "Actualizado",
            text: "La categoria se actualizo correctamente",
            icon: "success",
          });
        } else {
          this.modalShow = false;
          this.$swal({
            title: "Error",
            text: "No se pudo actualizar la categoria",
            icon: "error",
          });
        }
      } else {
        console.log("guardar",this.categoria);
        const res = await this.createCategoria({
          ...this.categoria,
          seccion: this.seccion,
        });
        if (res) {
          this.modalShow = false;
          this.$swal({
            title: "Categoria creada",
            text: "La categoria se ha creado correctamente",
            icon: "success",
            button: "Ok",
          });
        } else {
          this.modalShow = false;
          this.$swal({
            title: "Error",
            text: "No se pudo crear la categoria",
            icon: "error",
            button: "Ok",
          });
        }
      }
      this.loading = false;
    },
    async handleDeleteCategoria(id) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "¡No podrás revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Sí, eliminar!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteCategoria(id);
            if (res) {
              this.$swal({
                title: "Eliminado",
                text: "La categoria se elimino correctamente",
                icon: "success",
              });
            } else {
              this.$swal({
                title: "Error",
                text: "No se pudo eliminar la categoria",
                icon: "error",
              });
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      categorias: "categorias/categorias",
      categoriasProyectos: "categorias/categoriasProyectos",
      categoriasComercializacion: "categorias/categoriasComercializacion",
      categoriasPropiedades: "categorias/categoriasPropiedades",
    }),
    items(){
      
      if(this.seccion == 'proyectos') return this.categoriasProyectos
      if(this.seccion==='propiedades') return this.categoriasPropiedades

      return this.categoriasComercializacion

    },
  },
  async mounted() {
    await this.getCategorias();
  },
};
</script>

<style></style>
